//////////////////
/* entrypoints */
////////////////

module.exports = {
    entrypoint: {
      withdraw: 0,
      drain: 1,
    },
};
