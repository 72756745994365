import React from 'react';
//.sect
import Index from './Index';

export default function Explore() {
  return (
    <>
      <div id="explore-sub" className="sub-section">
        <Index />
      </div>
    </>
  );
}
